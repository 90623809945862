<template>
    <div class="relative block group w-full">
        <span
            class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0 group-focus:translate-y-0 group-focus:translate-x-0"
        />
        <multiselect
            v-model="value"
            class="border-current border-2 multiselect-custom"
            track-by="value"
            label="title"
            :placeholder="placeholder"
            select-label="+"
            deselect-label="-"
            selected-label=""
            :options="options"
            :searchable="false"
            :allow-empty="false"
        >
            <template #singleLabel="{ option }">
				<strong>{{ option.title }}</strong>
            </template>
			<template #option="{ option }">
                <span
					class="p-2 flex justify-between"
					:class="{'bg-disabled': option.isDisabled}"
				>
					{{ option.title }}
					<subscribe-is-needed-svg v-if="option.isDisabled" />
                </span>
            </template>
            <template #noOptions>
                <span class="text-sm font-bold tracking-widest text-black">Нет вариантов для выбора</span>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import SubscribeIsNeededSvg from '@/UI/Icons/SubscribeIsNeededSvg.vue';

export default {
    name: "BaseSelect",
    components: { Multiselect, SubscribeIsNeededSvg },
    props: {
        modelValue: {
            type: Object
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: "Выберите пункт"
        }
    },
	emits: [ 'onOpenSubscribe' ],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
				if (value.isDisabled) {
					return this.$emit('onOpenSubscribe');
				}
                this.$emit("onSelect", value);
            }
        }
    },
}
</script>

<style>
.multiselect-custom {
	cursor: pointer;
	box-sizing: border-box;
	color: black;
}

.multiselect--active {
	z-index: 30 !important;
}

.multiselect__spinner {
	background: transparent;
	width: 40px;
	height: 40px;
}

.multiselect__spinner:after, .multiselect__spinner:before {
	border-color: #fbb724 transparent transparent;
}

.multiselect-custom .multiselect__tags {
	min-height: inherit;
	padding: 10px 20px;
	border: none;
	border-radius: 0;
	font-size: 16px;
	line-height: 1.5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: transparent;
}

.multiselect__input, .multiselect__single {
	background: transparent;
	margin-bottom: 0;
}

.multiselect__single {
	display: block;
	padding: 2px 0;
	width: 100%;
	margin: 0;
	color: #000;
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.1rem;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.multiselect-custom .multiselect__placeholder {
	display: block;
	padding: 2px 0;
	width: 100%;
	margin: 0;
	color: #9CA3AF;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: transparent;
}

.multiselect__option {
	padding: 0px;
	min-height: inherit;
}

.multiselect-custom .multiselect__option--highlight {
	background: #F6E5CC;
	color: #000;
}

.multiselect-custom .multiselect__option--highlight::after {
	opacity: 0;
}

.multiselect-custom .multiselect__option--selected.multiselect__option--highlight {
	background: #F6E5CC;
	color: #000;
}

.multiselect-custom .multiselect__option--selected.multiselect__option--highlight::after {
	opacity: 0;
}

.multiselect__option--selected {
	background: #F6E5CC;
	color: #000;
}

.multiselect__content-wrapper {
	left: -2px;
	min-width: calc(100% + 4px);
	border-radius: 0;
	width: 100%;
	border: 2px solid #000000;
}

.multiselect__content-wrapper::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.multiselect--above .multiselect__content-wrapper {
	border: 2px solid #000000;
	border-radius: 0;
}

.multiselect__select {
	padding: 12px 20px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
}

.multiselect__select:before {
	display: block;
	position: initial;
	border-color: #000 transparent transparent transparent;
}

.multiselect__input {
	padding-left: 0;
}

.multiselect__input::placeholder {
	font-family: Roboto, sans-serif;
	color: #9ca3af;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1.4px;
}
</style>