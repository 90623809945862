<template>
    <button
        v-for="tab of tabs"
        :key="tab.value"
        class="relative group inline-block"
        @click="selectPeriod(tab)"
    >
        <span
            :class="{
                ['bg-orange']: tab.value === selectedKey,
                ['bg-orange-light']: tab.value !== selectedKey,
                ['translate-y-0']: tab.value === selectedKey,
                ['translate-x-0']: tab.value === selectedKey,
                ['bg-disabled']: tab.isDisabled,
                ['translate-x-1.5 transition-transform translate-y-1.5 group-hover:translate-y-0 group-hover:translate-x-0 pointer-events-none']: !tab.isDisabled
            }"
            class="absolute inset-0"
        />
        <span
            class="relative inline-block text-sm leading-5 font-bold tracking-widest py-3 px-3 border-2 border-current pointer-events-none"
        >
            <span class="flex items-center gap-2">
                <subscribe-is-needed-svg
                    v-if="tab.isDisabled"
                />   
                <span>{{ tab.value }}</span>
            </span>
        </span>
    </button>
</template>

<script>
import SubscribeIsNeededSvg from '@/UI/Icons/SubscribeIsNeededSvg.vue';

export default {
    name: "ReportsTabs",
    components: { SubscribeIsNeededSvg },
    props: {
        tabs: {
            type: Array,
            required: true
        },
        selectedKey: {
            type: String,
            required: true
        },
    },
    emits: [ 'toggle', 'onOpenSubscribe' ],
    methods: {
        selectPeriod(tab) {
            if (tab.isDisabled) {
                return this.$emit('onOpenSubscribe');
            }
            this.$emit('toggle', tab.value);
        }
    }
}
</script>
<style scoped></style>