<template>
    <page-loading
        :message="errors?.empty"
        :status="parentStatus"
        :empty="errors?.empty"
    >
        <div class="flex flex-col gap-[30px]">
            <h2
                v-if="data.heading"
                class="text-2xl"
            >
                {{ data.heading }}
            </h2>
            <subscribe-banner
                :status="subscribeStatus"
                :bannerText="isSubscribe ? data.premiumAccountBannerText : data.freeAccountBannerText"
                :btnText="isSubscribe ? data.premiumAccountButtonText : data.freeAccountButtonText"
                :open-popup="openPopUpSubscribe"
                class="mb-[6px]"
            />
            <p v-html="data.description" class="max-w-[900px] text-base" />
            <div class="flex flex-col gap-[20px] max-w-[900px]">
                <h3 class="text-lg font-normal">
                    Что дает подписка
                </h3>
                <subscribe-benefits-table />
            </div>
            <div class="flex flex-col gap-[20px]">
                <h3 class="text-lg font-normal">
                    Стоимость подписки
                </h3>
                <div class="flex gap-[10px] max-w-[860px]">
                    <discount-svg class="w-[30px] h-[30px]"/>
                    <p class="text-sm" v-html="data.discountText" />
                </div>
            </div>
            <div class="max-w-[900px]">
                <subscribe-tariff-table
                    :discount-percent="data.discountPercentForMembers || 0"
                />
            </div>
            <div class="flex gap-[20px]">
                <enter-button v-if="!isMember" class="max-w-[298px]" @click="openPopUpArda">
                    Стать участником Arda
                </enter-button>
                <enter-button class="max-w-[298px]" @click="openPopUpSubscribe">
                    {{ isSubscribe ? data.premiumAccountButtonText : data.freeAccountButtonText }}
                </enter-button>
            </div>
        </div>
    </page-loading>
    <subscribe-modal :pop-up="popUpSubscribe" :close-pop-up="closePopUpSubscribe" />
    <confirm-modal v-if="popUpArda" @close-modal="closePopUpArda" />
</template>

<script>
import { subscribePage } from "@/api";
import PageLoading from "@/components/PageLoading";
import SubscribeBanner from "@/components/SubscribePage/SubscribeBanner.vue";
import SubscribeBenefitsTable from "@/components/SubscribePage/SubscribeBenefitsTable.vue";
import singlePageHandler from "@/handlers/singlePageHandler";
import store from "@/store";
import { loadData } from "@/use/load";
import { toRef, ref } from "vue";
import DiscountSvg from "@/UI/Icons/DiscountSvg.vue";
import SubscribeTariffTable from "@/components/SubscribePage/SubscribeTariffTable.vue";
import EnterButton from "@/components/Enter/Button";
import ModalTemplate from "@/components/Modal/ModalTemplate";
import SubscribeModal from "@/components/Modal/SubscribeModal";
import ConfirmModal from "@/components/Modal/ConfirmModal";
import { overflowHidden, overflowShow } from "@/helpers";

export default {
    name: "SubscribePage",
    components: { PageLoading, SubscribeBanner, SubscribeBenefitsTable, DiscountSvg, SubscribeTariffTable, EnterButton, ModalTemplate, SubscribeModal, ConfirmModal },
    setup() {
        const { data: parentData, status: parentStatus } = loadData({
            query: subscribePage,
            handler: singlePageHandler,
        });
        const data = toRef(parentData, "data");
        const errors = toRef(parentData, "errors");

        const subscribeStatus = store.getters["getSubscribe"];
        const isSubscribe = store.getters["isSubscribe"];
        const isMember = store.getters["isMember"];

        const popUpSubscribe = ref(false);
        const popUpArda = ref(false);

        return {
            data,
            parentStatus,
            errors,
            isSubscribe,
            subscribeStatus,
            isMember,
            popUpSubscribe,
            popUpArda,
        };
    },
    methods: {
        openPopUpSubscribe() {
            this.popUpSubscribe = true;
            overflowHidden();
        },
        closePopUpSubscribe() {
            this.popUpSubscribe = false;
            overflowShow();
        },
        openPopUpArda() {
            this.popUpArda = true;
            overflowHidden();
        },
        closePopUpArda() {
            this.popUpArda = false;
            overflowShow();
        },
    }
};
</script>
<style scoped></style>
