import { ADMIN, KEYS, MEMBER, PREMIUM } from "@/constants";
import { createStore } from "vuex";
export default createStore({
    state() {
        return {
            token: null,
            user: {
                email: null,
                name: null,
                lastName: null,
                post: null,
                company: null,
                role: null,
                resourceMap: null,
                userPermissions: null,
                adminCalendar: false,
                subscribe: null
            },
            calendarAdminMode: false,
            eventCard: null
        };
    },
    getters: {
        user(state) {
            return state.user;
        },
        isMember(state) {
            return state.user.role === MEMBER;
        },
        isAuth(state) {
            return !!state.token
        },
        getToken(state) {
            return state.token
        },
        isAdmin(state) {
            return state.user.userPermissions === ADMIN;
        },
        isSubscribe(state) {
            return state.user.subscribe === PREMIUM;
        },
        calendarAdminMode(state) {
            return state.calendarAdminMode;
        },
        eventCard(state) {
            return state.eventCard;
        },
        getPermissionChangeCalendar(state) {
            return state.user.adminCalendar
        },
        getSubscribe(state) {
            return state.user.subscribe
        }
    },
    mutations: {
        logout(state) {
            console.log('logout')
            localStorage.removeItem(KEYS.TOKEN);
            localStorage.removeItem(KEYS.USER);

            state.token = null;
            state.user = {
                email: null,
                name: null,
                lastName: null,
                post: null,
                company: null,
                role: null,
                resourceMap: null,
                userPermissions: null,
                adminCalendar: false,
                subscribe: null
            }
        },
        getToken(state, payload) {
            state.token = payload
        },
        getUser(state, { email, name, lastName, post, company, userRole, resourceMap, userPermissions, adminCalendar, subscribe }) {
            state.user.email = email;
            state.user.name = name;
            state.user.lastName = lastName;
            state.user.post = post;
            state.user.company = company;
            state.user.role = userRole;
            state.user.resourceMap = resourceMap;
            state.user.userPermissions = userPermissions;
            state.user.adminCalendar = adminCalendar;
            state.user.subscribe = subscribe;
        },
        toggleCalendarAdminMode(state) {
            state.calendarAdminMode = !state.calendarAdminMode
        },
        setEventCard(state, newValue) {
            state.eventCard = { ...newValue }
        }
    }
});
