import { required } from "@/helpers";
import { REQUIRED_FIELD } from "@/constants";

export default {
    name: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    link: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    }
}