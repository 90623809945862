export function usePrice() {
    const calculateDiscountedPrice = (price, discountPercent) => {
        const discountFactor = (100 - discountPercent) / 100;
        return Math.round(price * discountFactor);
    };

    const calculatePerMonths = (price, period) => {
        const result = price / period;
        return Math.round(result * 100) / 100;
    };

    return {
        calculateDiscountedPrice,
        calculatePerMonths,
    };
}