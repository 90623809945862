<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
        :errors="errors"
        class="overflow-x-hidden"
    >
        <button-up />
        <base-sources
            :heading="data.heading"
            :caption="data?.caption"
            :subheading="data?.subheading"
            :sources="data?.sources"
            class="mb-[60px] reports"
        />
        <div class="flex gap-4 items-start">
            <reports-preview
                :reports="data.reports"
                :years="years"
                :active-year="idYear"
                :content="activeYear"
                :quarter="activeYear?.quarter"
                :month="activeYear?.month"
                :weeks="activeYear?.week"   
                @toggle="toggle"
                @on-open-subscribe="openSubscribePopUp"
            />
        </div>
        <div class="relative w-full mx-auto max-w-[600px]">
            <partner-banner
                :logo-url="data.partner[0].logo.data.attributes.url"
                :website-link-url="data.partner[0].websiteUrl"
                :website-link-text="data.partner[0].linkLabel"
            />
        </div>
    </page-loading>
    <subscribe-is-needed
        :pop-up="subscribePopUp"
        :close-pop-up="closeSubscribePopUp"
    />
</template>

<script>
import { loadData } from "@/use/load";
import PageLoading from "@/components/PageLoading";
import { ref, toRef } from "vue";
import { reportsPage } from "@/api";
import listHandler from "../handlers/listHandler";
import BaseSources from "@/components/Reports2/BaseSources";
import ReportsPreview from "@/components/Reports2/ReportsPreview";
import ButtonUp from "@/components/Reports2/components/ButtonUp";
import PartnerBanner from "@/components/VcBlogAnalytics/components/PartnerBanner";
import { overflowShow } from "@/helpers";
import SubscribeIsNeeded from "@/components/Subscribe/SubscribeIsNeeded.vue";
import store from "@/store";

export default {
    name: "ReportsPage3",
    components: { PartnerBanner, ButtonUp, PageLoading, ReportsPreview, BaseSources, SubscribeIsNeeded, },
    setup() {
        const { data: response, status } = loadData({ query: reportsPage, handler: listHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        const subscribePopUp = ref(false);

        return {
            data,
            status,
            errors,
            subscribePopUp
        };
    },

    data() {
        return {
            showEdit: false,
            selectedYear: null,
            isSubscribe: store.getters['isSubscribe'],
        }
    },
    computed: {
        years() {
            if (!this.data.list) return []
            return Object.keys(this.data.list).map((year) => {
                const reports = this.data.list[year];
                const hasReports = Object.values(reports).some(type => type.length > 1);
                const isDisabled = !this.isSubscribe && !hasReports;

                return { value: year, isDisabled };
            });
        },
        activeYear() {
            if (!this.data.list) {
                return {}  
            } 

            return this.data.list[this.idYear] || {};
        },
        idYear() {
            const availableYears = this.years.filter((year) => !year.isDisabled);
            const lastAvailableYear = availableYears.length > 0 ? availableYears[availableYears.length - 1].value : null;

            return this.selectedYear ?? lastAvailableYear ?? Object.keys(this.data.list).at(-1);
        },
        domain() {
            return process.env.VUE_APP_API_HOST;
        }
    },
    methods: {
        toggle(year) {
            this.selectedYear = String(year);
        },
        openSubscribePopUp() {
            this.subscribePopUp = true;
            overflowShow();
        },
        closeSubscribePopUp() {
            this.subscribePopUp = false;
            overflowShow();
        },
    }
}
</script>

<style>
.reports .subheading  a {
	background-color: #F6E5CC;
}
</style>
