<template>
    <div
        class="relative group inline-block cursor-pointer"
        @click="handleSelect"
    >
        <span
            :class="[
                isSelected ? ['bg-orange translate-x-0 translate-y-0'] : ['bg-orange-light'],
                disabled ? 'bg-disabled' : '',
            ]"
            class="absolute inset-0
                translate-x-1.5
                transition-transform
                translate-y-1.5 group-hover:translate-y-0 group-hover:translate-x-0"
        />
        <div
            class="relative flex gap-2 inline-block p-4 border-2 border-current"
        >
            <subscribe-is-needed-svg v-if="disabled"/>
            {{ label }}
        </div>
    </div>
</template>

<script>
import SubscribeIsNeededSvg from '@/UI/Icons/SubscribeIsNeededSvg.vue';

export default {
    name: "PeriodSelectionButton",
    components: { SubscribeIsNeededSvg },
    props: {
        id: {
            type: [ String, Number ],
            required: true
        },

        label: {
            type: String,
            required: true
        },

        isSelected: {
            type: Boolean,
            required: true
        },

        disabled: {
            type: Boolean,
        }
    },

    emits: [ "select", "onOpenSubscribe" ],

    methods: {
        handleSelect() {
            this.disabled ? this.$emit("onOpenSubscribe") : this.$emit("select", this.id);   
        },
    },
}
</script>

<style scoped>

</style>
