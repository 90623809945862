<template>
    <div class="flex flex-col items-center sm:items-stretch">
        <h2
            v-if="heading"
            class="text-2xl mb-5"
            v-text="heading"
        />

        <p
            v-if="description"
            class="mb-10 max-w-[600px] text-center sm:text-left"
            v-text="description"
        />

        <div class="mb-10 max-w-[1312px]">
            <period-selection-panel
                v-if="periodKeys"
                class="flex justify-center sm:justify-start"
                :period-ids="periodKeys"
                :initial-selected-period-id="selectedPeriodKey"
                :is-subscribe="isSubscribe"
                @period-change="handlePeriodChange"
            />
        </div>

        <div
            v-if="selectedPeriodKey && statistics"
            class="mb-8 flex flex-wrap md:flex-col justify-center gap-4 max-w-[1312px]"
        >
            <template
                v-for="(counts, criterion) in statistics.statsByPeriod[selectedPeriodKey]"
                :key="criterion"
            >
                <div class="relative w-[calc(50%-0.5rem)] md:w-full md:max-w-[600px] border border-black p-5">
                    <span class="absolute -z-10 bg-orange-light bg-opacity-30 inset-0 translate-x-1.5 transition-transform translate-y-1.5 group-hover:translate-y-0 group-hover:translate-x-0" />
                    <criterion-stats
                        :count-label="labels[criterion].blogCountLabel"
                        :criterion-label="labels[criterion].criterionLabel"
                        :total-count-label="labels[criterion].maxCountLabel"
                        :period-label="selectedPeriodLabel"
                        :count-for-blog="counts.blogCount"
                        :count-for-all-blogs="counts.maxCount"
                        :percentage="counts.percentage"
                        :percentile="counts.percentile"
                    />
                </div>
            </template>
        </div>

        <p
            v-if="statistics"
            class="mb-8 text-center text-sm"
        >
            Последнее обновление: {{ statistics.latestUpdateDatetime }}
        </p>

        <div class="w-full max-w-[600px]">
            <partner-banner
                v-if="shouldDisplayPartnerBanner"
                :logo-url="partnerLogoUrl"
                :website-link-url="partner.websiteUrl"
                :website-link-text="partner.linkLabel"
            />
        </div>
    </div>
</template>

<script>
import PartnerBanner from "@/components/VcBlogAnalytics/components/PartnerBanner";
import CriterionStats from "@/components/VcBlogAnalytics/components/CriterionStats";
import PeriodSelectionPanel from "@/components/VcBlogAnalytics/components/PeriodSelectionPanel";
import { vcBlogAnalyticsConstants } from "@/constants";
import store from "@/store";

const { CUMULATIVE_VC_BLOG_STATS_KEY, CUMULATIVE_VC_BLOG_STATS_LABEL } = vcBlogAnalyticsConstants;

const CRITERIA_LABELS = {
    posts: {
        blogCountLabel: "Количество постов",
        criterionLabel: "по количеству постов",
        maxCountLabel: "За 100% здесь взято максимальное количество постов среди участников IT-кластера"
    },
    views: {
        blogCountLabel: "Количество просмотров",
        criterionLabel: "по количеству просмотров",
        maxCountLabel: "За 100% здесь взято максимальное количество просмотров среди участников IT-кластера"
    },
    likes: {
        blogCountLabel: "Количество лайков",
        criterionLabel: "по количеству лайков",
        maxCountLabel: "За 100% здесь взято максимальное количество лайков среди участников IT-кластера"
    },
    bookmarks: {
        blogCountLabel: "Количество добавлений поста в закладку",
        criterionLabel: "по количеству добавлений поста в закладку",
        maxCountLabel: "За 100% здесь взято максимальное количество добавлений поста в закладки " +
            "среди участников IT-кластера"
    },
    comments: {
        blogCountLabel: "Количество комментариев",
        criterionLabel: "по количеству комментариев",
        maxCountLabel: "За 100% здесь взято максимальное количество комментариев среди участников IT-кластера"
    }
};

function generatePeriodLabel(periodKey) {
    if (periodKey === CUMULATIVE_VC_BLOG_STATS_KEY) {
        return CUMULATIVE_VC_BLOG_STATS_LABEL;
    }

    return `за ${periodKey} год`;
}

export default {
    name: "VcBlogAnalyticsStatsScreen",

    components: {
        PeriodSelectionPanel,
        CriterionStats,
        PartnerBanner
    },

    props: {
        heading: {
            type: String,
            required: true
        },

        description: {
            type: String,
            required: true
        },

        statistics: {
            type: Object,
            required: true
        },

        partner: {
            type: Object,
            required: true
        }
    },

    setup() {
        const isSubscribe = store.getters["isSubscribe"];
      
        return {
            isSubscribe,
        }
    },

    data() {
        return {
            labels: CRITERIA_LABELS,
            selectedPeriodKey: CUMULATIVE_VC_BLOG_STATS_KEY
        }
    },

    computed: {
        domain() {
            return process.env.VUE_APP_API_HOST;
        },

        partnerLogoUrl() {
            const logoUrlEndpoint = this.partner?.logoUrl;

            if (!logoUrlEndpoint) {
                return null;
            }

            return this.domain + logoUrlEndpoint;
        },

        shouldDisplayPartnerBanner() {
            const partnerInformation = this.partner;

            if (!partnerInformation) {
                return false;
            }

            const { websiteUrl, linkLabel, logoUrl } = partnerInformation;
            return Boolean(websiteUrl && linkLabel && logoUrl);
        },

        periodKeys() {
            if (!this.statistics) {
                return null;
            }

            return Object.keys(this.statistics.statsByPeriod);
        },

        selectedPeriodLabel() {
            return generatePeriodLabel(this.selectedPeriodKey);
        }
    },

    methods: {
        handlePeriodChange(periodKey) {
            this.selectedPeriodKey = periodKey;
        },

        generatePeriodButtonLabel(periodKey) {
            if (periodKey === CUMULATIVE_VC_BLOG_STATS_KEY) {
                return CUMULATIVE_VC_BLOG_STATS_LABEL;
            }

            return `${periodKey} год`;
        }
    }
};
</script>

<style scoped></style>
