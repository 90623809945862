<template>
    <subscribe-modal-wrapper>
        <subscribe-is-needed-modal
            :popUp="popUp"
            :closePopUp="closePopUp"
        />
    </subscribe-modal-wrapper>
</template>

<script>
import SubscribeIsNeededModal from '../Modal/SubscribeIsNeededModal.vue';
import SubscribeModalWrapper from '../Modal/SubscribeModalWrapper.vue';
export default {
    name: "SubscribeIsNeeded",
    components: { SubscribeModalWrapper, SubscribeIsNeededModal },
    props: {
        popUp: {
            type: Boolean,
            default: false,
        },
        closePopUp: {
            type: Function,
            required: true
        },
    },
}
</script>
<style lang="scss" scoped></style>