<template>
    {{ status }}
    <query-modal
        :show-error="true"
        :error="status.isError"
        :pending="status.isPending"
        :success="status.isSuccess"
        @close="closeModal"
    >
        <template #modal>
            <div class="max-w-[365px]">
                <p class="my-4">
                Чтобы получить дополнительную скидку на подписку и другие преимущества участника ARDA, надо просто разместить логотип ARDA на вашем сайте.
                </p>
                <p class="my-4 mb-8">
                    Нажмите на кнопку “Вступить в ARDA”, и мы свяжемся с вами для обмена логотипами
                </p>
                <enter-button
                    class="w-full"
                    @click="onSubmit"
                >
                    Вступить в ARDA
                </enter-button>
            </div>
        </template>
        <template #success>
            Заявка на вступление в ARDA успешно отправлена!
        </template>
        <template #error>
            <p class="mb-4 text-center">
                Ошибка при отправке формы:
            </p>
            <p class="m-0 text-center">
                {{ error }}
            </p>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import QueryModal from "@/components/QueryModal";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import { joinPersonal } from "@/api";

export default {
    name: "ConfirmModal",
    components: { EnterButton, QueryModal },
    setup() {
        const init = submit({
            submit: joinPersonal,
            fields: {}
        });

        const callback = toRef(init, "onSubmit");
        const status = toRef(init, "status");
        const error = toRef(init, "error");
        return { submit: callback, status, error };
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        onSubmit() {
            this.submit()
        }
    }
};
</script>

<style scoped></style>
