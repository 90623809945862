<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <div class="flex flex-wrap items-center justify-between max-w-full">
            <h2
                v-if="data.heading"
                class="text-2xl mb-5"
            >
                {{ data.heading }}
            </h2>
        </div>

        <webinar-board
            :message="errors?.list"
            :error="!!errors?.list"
            :webinars="data.list"
            :is-subscribe="isSubscribe"
            @on-open-subscribe="openSubscribePopUp"
        />
    </page-loading>
    <subscribe-is-needed 
        :pop-up="subscribePopUp"
        :close-pop-up="closeSubscribePopUp"
    />
</template>

<script>
import {loadData} from "@/use/load";
import {webinarsPage} from "@/api";
import {ref, toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import listHandler from "../handlers/listHandler";
import WebinarBoard from "@/components/WebinarsPage/WebinarBoard";
import JoinAssociation from "@/components/JoinAssociation";
import { overflowShow } from "@/helpers";
import SubscribeIsNeeded from "@/components/Subscribe/SubscribeIsNeeded.vue";
import useSubscriptionInfo from "@/use/useSubscriptionInfo";

export default {
    name: "WebinarsPage",
    components: { WebinarBoard, PageLoading, JoinAssociation, SubscribeIsNeeded },
    setup() {
        const { data: response, status } = loadData({ query: webinarsPage, handler: listHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        const { isSubscribe } = useSubscriptionInfo();
        const subscribePopUp = ref(false);

        return {
            data,
            errors,
            status,
            isSubscribe,
            subscribePopUp,
        };
    },

    methods: {
        openSubscribePopUp() {
            this.subscribePopUp = true;
            overflowShow();
        },
        closeSubscribePopUp() {
            this.subscribePopUp = false;
            overflowShow();
        }
    }
};
</script>

<style scoped></style>
