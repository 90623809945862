<template>
	<base-multi-select
		v-model="value"
		class="w-full bg-white"
		:name="name"
		:options="options"
		:placeholder="placeholder"
		:disabled="disabled"
	/>
</template>

<script>
import BaseMultiSelect from "@/UI/BaseMultiSelect";

export default {
	name: "StaticFilter",
	components: { BaseMultiSelect },
	props: {
		modelValue: {
			type: Array,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		options: {
			type: Array,
			required: true
		},
		placeholder: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			required: false,
		}
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("onSelect", value);
			}
		}
	},
}
</script>

<style scoped>

</style>