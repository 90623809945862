<template>
    <modal-template
        v-if="popUp"
        @on-close="closePopUp"
    >
        <div class="w-[400px] min-w-[300px] sm:w-full">
            <div class="flex items-center mb-5">
                <h2 class="text-lg font-medium">
                    <span v-if="isSubscribe">
                        Продлить подписку
                    </span>
                    <span v-else>
                        Оформить подписку
                    </span>
                </h2>
            </div>
            <div class="flex flex-col gap-4">
                <p v-if="isSubscribe">
                    Новый период подписки начнется сразу после окончания текущего оплаченного периода
                </p>
                <p>Выберите период подписки:</p>
                <div
                    v-for="period in data"
                    :key="period.id"
                    class="mb-2"
                >
                    <base-checkbox
                        :id="'period-' + period.id"
                        :name="'period'"
                        :modelValue="selectedPeriod === period.id"
                        @update:modelValue="onPeriodChange(period)"
                    >
                        <div class="absolute left-10 bottom-2 w-[300px]">
                            <span class="flex gap-5">
                                <span>{{ period.durationInMonths }} мес.</span>
                                <span>
                                    {{ useFormattedPrice(getPrice(period.price)) }} ₽
                                </span>
                                <span v-if="isMember" class="text-discount-price line-through">
                                    {{ useFormattedPrice(period.price) }} ₽
                                </span>
                            </span>
                        </div>
                    </base-checkbox>
                </div>
                <div class="flex flex-col gap-1">
                    <p>Комментарий</p>
                    <input
                        v-model="commentaryValue"
                        type="textarea"
                        class="w-full min-h-[82px] bg-transparent inline-block px-5 text-xs tracking-widest border-2 border-current"
                        label="Описание"
                        placeholder="Можете оставить здесь дополнительный комментарий"
                    />
                </div>
                <p v-if="errorMessage" class="text-red text-base">{{ errorMessage }}</p>
                <enter-button @click="submit">
                    Отправить заявку
                </enter-button>
            </div>
        </div>
    </modal-template>
    <modal-template
        v-if="confirmPopUp"
        @on-close="closeConfirmPopUp"
    >
        <div class="min-h-[118px] flex items-center">
            <div v-if="isSubscribe">
                Заявка на продление подписки успешно отправлена!
            </div>
            <div v-else>
                Заявка на оформление подписки успешно отправлена!
            </div>
        </div>
    </modal-template>
</template>

<script>
import { ref, onMounted } from "vue";
import ModalTemplate from "@/components/Modal/ModalTemplate";
import BaseCheckbox from "@/UI/BaseCheckbox";
import { createSubscribe, getSubscribePeriods } from "@/api";
import { mainHandler } from "@/handlers";
import BaseInput from "@/UI/BaseInput";
import EnterButton from "@/components/Enter/Button";
import { overflowHidden } from "@/helpers";
import { usePrice } from "@/use/usePrice";
import usePeriodSelection from "@/use/usePeriodSelection";
import useSubscriptionInfo from "@/use/useSubscriptionInfo";
import { useFormattedPrice } from "@/use/useFormattedPrice";

export default {
    name: "SubscribeModal",
    components: { ModalTemplate, BaseCheckbox, BaseInput, EnterButton, },
    props: {
        popUp: {
            type: Boolean,
            default: false,
        },
        closePopUp: {
            type: Function,
            required: true
        },
    },
    setup (props) {
        const { calculateDiscountedPrice } = usePrice();
        const { selectedDuration, selectedPeriod, selectedPrice, errorMessage, onPeriodChange} = usePeriodSelection();
        const { isSubscribe, isMember} = useSubscriptionInfo();

        const data = ref([]);
        const discountPercentForMembers = ref(0);
        const errors = ref(null);
        const status = ref("pending");

        const commentaryValue = ref('');

        const confirmPopUp = ref(false);


        const fetchPeriodsData = async () => {
            try {
                const response = await getSubscribePeriods();
                const result = mainHandler(response.data);

                if (result.error) {
                    throw result.errors;
                }

                data.value = result.data;
                discountPercentForMembers.value = response.data.discountPercentForMembers;
                status.value = "success";
            } catch (error) {
                errors.value = error;
                status.value = "error";
            }
        };

        const getPrice = (price) => {
            return isMember
                ? calculateDiscountedPrice(price, discountPercentForMembers.value)
                : price;
        };

        const submit = async () => {
            if (!selectedPeriod.value) {
                errorMessage.value = 'Выберите период подписки'
                return;
            }

            const data = {
                type: isSubscribe ? "продление" : "оформление",
                status: "новая",
                price: getPrice(selectedPrice.value),
                durationInMonths: selectedDuration.value,
                clientComment: commentaryValue.value || null, 
            };

            try {
                const response = await createSubscribe({data: data});
                errorMessage.value = null;
                confirmPopUp.value = true;
                overflowHidden();
                props.closePopUp();
            } catch (error) {
                errorMessage.value = error;
            }
        };

        onMounted(fetchPeriodsData);

        return {
            isSubscribe,
            isMember,
            data,
            discountPercentForMembers,
            calculateDiscountedPrice,
            useFormattedPrice,
            errors,
            status,
            selectedPeriod,
            selectedPrice,
            selectedDuration,
            errorMessage,
            commentaryValue,
            confirmPopUp,
            onPeriodChange,
            getPrice,
            submit,
        };
    },

    methods: {
        openConfirmPopUp() {
            this.confirmPopUp = true;
            this.overflowHidden();
        },
        closeConfirmPopUp() {
            this.confirmPopUp = false;
            this.overflowShow();
        },
    }
}
</script>
<style lang="scss" scoped></style>
