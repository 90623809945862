<template>
    <modal-template
        v-if="popUp"
        @on-close="closePopUp"
    >
        <div class="flex flex-col gap-4 max-w-[365px]">
            <p>Эта опция доступна только по подписке.</p>
            <p>Вы можете подробнее ознакомиться с полным списком преимуществ, которые она предоставляет, <span>на странице подписке</span> или отправить заявку на оформление подписки прямо сейчас.</p>
            <enter-button @click="openSubscribeModal">
                Оформить подписку
            </enter-button>
        </div>
    </modal-template>
</template>

<script>
import ModalTemplate from "@/components/Modal/ModalTemplate";
import EnterButton from "@/components/Enter/Button";
import { subscribeModalActions, subscribeProviderKey } from "@/constants";
import { inject } from "vue";

export default {
    name: "SubscribeIsNeededModal",
    components: { ModalTemplate, EnterButton },
    props: {
        popUp: {
            type: Boolean,
            default: false,
        },
        closePopUp: {
            type: Function,
            required: true
        },
    },
    setup (props) {
        const subscribePopUpActions = inject(subscribeProviderKey);

        const openSubscribeModal = () => {
            if (!subscribePopUpActions) {
                return;
            }
            subscribePopUpActions[subscribeModalActions.OPEN_SUBSCRIBE_MODAL]();
            props.closePopUp();
        }

        return {
            openSubscribeModal,
        };
    },
}
</script>
<style lang="scss" scoped></style>
