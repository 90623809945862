<template>
    <div
        class="max-w-[900px] w-full min-h-[178px] relative group inline-block"
    >
        <span
            class="absolute w-full h-full inset-0 translate-x-2 translate-y-2 transition-transform"
            :class="status === 'Premium' ? 'bg-orange-light' : 'bg-back-gray'"
        />
        <div
            class="relative w-full h-full flex inline-block p-4 border-2 border-current flex-col gap-3" 
        >
            <p
                class="text-base sm:text-base font-bold"
            >
                {{ status }}
            </p>
            <p v-html="formattedBannerText" class="mb-[15px] text-base" />
            <enter-button @click="openPopup" class="max-w-[298px]">
                {{  btnText }}
            </enter-button>
        </div>
    </div>
</template>

<script>
import EnterButton from '../Enter/Button.vue';
import store from "@/store";
import { computed } from "vue";

export default {
    name: "SubscribeBanner",
    components: { EnterButton },
    props: {
        status: String,
        bannerText: String,
        btnText: String,
        openPopup: Function,
    },
    setup(props) {
        const user = store.getters["user"];

        const formatDate = (date) => {
            if (!date) return '';
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
        };
        
        const formattedBannerText = computed(() => {
            return props.bannerText.replace(
                /{subscriptionExpireDate}/g,
                formatDate(user.resourceMap.subscriptionExpireDate)
            );
        });

        return {
            formattedBannerText
        }
    }
};
</script>
<style module></style>
