<template>
    <table>
        <thead>
            <tr class="border-b border-gray-300 bg-back-gray">
                <th class="py-[10px]"></th>
                <th 
                    v-for="header in data"
                    :key="header.durationInMonths"
                    class="py-[10px] text-sm text-center w-[400px]"
                >
                    {{ header.durationInMonths }} мес.
                </th>
                <hr class="w-full"/>
            </tr>
        </thead>
        <tbody>
            <tr 
                class="text-sm border-b border-gray-300 text-center"
            >
                <td class="p-[10px] w-[225px] text-center">Стандартные тарифы</td>
                <td 
                    class="p-[10px] w-[400px]" 
                    v-for="price in data" 
                    :key="price.id"
                >
                    <span class="flex flex-col">
                        <span>
                            {{ useFormattedPrice(price.price) }} ₽
                        </span>
                        <span>
                            ({{ useFormattedPrice(calculatePerMonths(price.price, price.durationInMonths)) }} ₽/мес.)
                        </span>
                    </span>
                </td>
            </tr>
            <tr 
                class="text-sm border-b border-gray-300 text-center"
            >
                <td class="p-[10px] w-[225px] text-center">Тарифы для участников ARDA</td>
                <td 
                    class="p-[10px] w-[400px]" 
                    v-for="price in data" 
                    :key="price.id"
                >
                    <span class="flex flex-col">
                        <span>
                            {{ useFormattedPrice(calculateDiscountedPrice(price.price, discountPercent)) }} ₽
                        </span>
                        <span>
                            ({{ useFormattedPrice(calculatePerMonths(calculateDiscountedPrice(price.price, discountPercent), price.durationInMonths)) }} ₽/мес.)
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { getSubscribePeriods } from "@/api";
import { mainHandler } from "@/handlers";
import { useFormattedPrice } from "@/use/useFormattedPrice";
import { usePrice } from "@/use/usePrice";
import { ref, onMounted } from "vue";

export default {
    name: "SubscribeTariffTable",
    props: {
        discountPercent: {
            type: Number,
            required: true,
        },
    },
    setup() {
        const { calculateDiscountedPrice, calculatePerMonths } = usePrice();

        const data = ref([]);
        const errors = ref(null);
        const status = ref("pending");

        const fetchPeriodsData = async () => {
            try {
                const response = await getSubscribePeriods();
                const result = mainHandler(response.data);

                if (result.error) {
                    throw result.errors;
                }

                data.value = result.data;
                status.value = "success";
            } catch (error) {
                errors.value = error;
                status.value = "error";
            }
        };

        onMounted(fetchPeriodsData);

        return {
            data,
            errors,
            status,
            calculatePerMonths,
            calculateDiscountedPrice,
            useFormattedPrice,
        };
    },
};
</script>

<style scoped></style>
