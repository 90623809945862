<template>
    <modal-template
        v-if="popUp"
        @on-close="closePopUp"
    >
        <div class="w-[400px] flex flex-col gap-5">
            <h2 class="text-lg font-bold">Добавить телеграм-канал</h2>
            <form 
                @submit.prevent="onSubmit" 
                action="" 
                class="flex flex-col gap-4"
            >
                <base-input 
                    :label="'Название телеграм-канал'"
                    :placeholder="'Введите название канала'"
                    v-model.trim="field.name.value"
                    :error="field.name.isError"
                    :message="field.name.message"
                    @input="field.name.input"
                    @blur="field.name.blur"
                />
                <base-input 
                    :label="'Ссылка на телеграм-канал'"
                    :placeholder="'Введите ссылку на канал'"
                    v-model.trim="field.link.value"
                    :error="field.link.isError"
                    :message="field.link.message"
                    @input="field.link.input"
                    @blur="field.link.blur"
                />
                <enter-button type="submit">
                    Добавить канал
                </enter-button>
            </form>
        </div>
    </modal-template>
    <modal-template  v-if="confirmPopUp" @on-close="closeConfirmPopUp">
        <div class="flex items-center min-h-[118px]">
            Заявка на добавление телеграм-канала успешно отправлена!
        </div>
    </modal-template>
</template>

<script>
import BaseInput from "@/UI/BaseInput";
import EnterButton from "@/components/Enter/Button";
import { overflowHidden, overflowShow } from "@/helpers";
import submitForm from "@/use/submitForm";
import { ref, toRef } from "vue";
import { addingTelegramChannel } from "@/api";
import addTelegramChannel from "@/configForms/addTelegramChannel";
import ModalTemplate from "./ModalTemplate.vue";

export default {
    name: "TelegramAddChannel",
    components: { EnterButton, ModalTemplate, BaseInput },
    props: {
        popUp: {
            type: Boolean,
            default: false,
        },
        closePopUp: {
            type: Function,
            required: true
        },
    },
    setup() {
        const submit = submitForm(addTelegramChannel);

        const form = toRef(submit, "form");
        const responseError = toRef(submit, "responseError");

        const confirmPopUp = ref(false);
        return {
            form,
            responseError,
            confirmPopUp,
        };
    },
    computed: {
        field() {
            const { name, link } = this.form.fields;
            return {
                name,
                link
            };
        }
    },

    methods: {
        async onSubmit() {
            if (!this.form.valid) {
                this.form.showErrors()
                return;
            }

            const data = {
                status: "новая",
                telegramChannelName: this.field.name.value,
                telegramChannelLink: this.field.link.value,
            };

            try {
                const response = await addingTelegramChannel({data: data});
                this.confirmPopUp = true;
                overflowHidden();
                this.closePopUp();
            } catch (error) {
                console.log(error);
                this.responseError(error);
            }
        },

        openConfirmPopUp() {
            this.confirmPopUp = true;
            overflowHidden();
        },

        closeConfirmPopUp() {
            this.confirmPopUp = false;
            overflowShow();
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
