export default {
    landing: {
        name: "welcome"
    },
    account: {
        name: "account",
        auth: {
            name: "auth"
        },
        main: {
            name: "main"
        },
        profile: {
            name: "profile"
        },
        standards: {
            name: "standards"
        },
        webinars: {
            name: "webinars"
        },
        resource: {
            name: "resource"
        },
        vcBlogAnalytics: {
            name: "vc-blog-analytics"
        },
        calendar: {
            name: "calendar"
        },
        faq: {
            name: "FAQ"
        },
        exchange: {
            name: "exchange"
        },
        links: {
            name: "links"
        },
        reports: {
            name: "reports"
        },
        prSupport: {
            name: "prSupport"
        },
        cardEdit: {
            name: "cardEdit"
        },
        formMap: {
            name: "formMap"
        },
        whatsNew: {
            name: "whatsNew"
        },
        subscribe: {
            name: "subscribe"
        }
    }
};
