<template>
  <div>
    <slot />
    <subscribe-modal
      :popUp="subscribePopUp"
      :closePopUp="closeSubscribePopUp"
    /> 
  </div>
</template>

<script>
import { ref, provide } from 'vue';
import SubscribeModal from "@/components/Modal/SubscribeModal";
import { subscribeModalActions, subscribeProviderKey } from '@/constants';

export default {
  name: 'SubscribeModalWrapper',
  components: { SubscribeModal },
  setup() {
    const subscribePopUp = ref(false);

    const openSubscribePopUp = () => {
      subscribePopUp.value = true;
    };

    const closeSubscribePopUp = () => {
      subscribePopUp.value = false;
      overflowShow();
    };

    const subscribePopUpActions = {
      [subscribeModalActions.OPEN_SUBSCRIBE_MODAL]: openSubscribePopUp,
      [subscribeModalActions.CLOSE_SUBSCRIBE_MODAL]: closeSubscribePopUp,
    };

    provide(subscribeProviderKey, subscribePopUpActions);

    return {
      subscribePopUp,
      closeSubscribePopUp,
    };
  },
};
</script>
