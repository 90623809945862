<template>
    <component
        class="max-w-sm w-full min-h-[250px] mb-10 relative group inline-block"
        :is="tag"
        target="_blank"
        :href="standard.link"
    >
        <span
            class="absolute w-full h-full inset-0 translate-x-2 translate-y-2 bg-orange-light transition-transform"
            :class="{
                'group-hover:translate-y-0 group-hover:translate-x-0': isLink,
            }"
        />
        <div
            class="relative w-full h-full flex inline-block p-4 border-2 border-current flex-col"
        >
            <h2
                v-if="standard.heading"
                class="text-lg mb-2 sm:text-base"
            >
                {{ standard.heading }}
            </h2>
            <p
                v-if="standard.description"
                class="mb-5"
                v-html="standard.description"
            />
            <div class="flex justify-between mt-auto">
                <span class="text-xs text-gray-700 bottom-4">
                {{ standard.status }}
                </span>
                <a
                    class="text-xs text-black hover:underline bottom-4 font-bold"
                    :href="fileLink"
                >
                  Скачать архив
                </a>
            </div>
        </div>
    </component>
</template>

<script>
import {API_HOST} from "@/constants";

export default {
    name: "StandardItem",
    props: {
        standard: {
            type: Object,
            required: true
        },
    },
    computed: {
        fileLink() {
          return  this.standard.file ? API_HOST + this.standard.file.url : null
        },
      tag() {
        return this.standard.link ? "a" : "div";
      },
      isLink() {
        return this.tag === "a";
      }
    }
};
</script>

<style scoped></style>
