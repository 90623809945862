import { computed } from "vue";
import store from "@/store";

export default function useSubscriptionInfo() {
    const isSubscribe = computed(() => store.getters["isSubscribe"]).value;
    const isMember = computed(() => store.getters["isMember"]).value;

    return {
        isSubscribe,
        isMember
    };
}
