<template>
    <div class="w-full pr-2 pb-2 flex">
        <div class="relative flex w-full">
            <span
                class="w-full absolute inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md"
            />
            <div class="w-full relative flex flex-col p-4 border-2 border-current">
                <div
                    v-if="image"
                    class="relative mb-6 "
                >
                    <div class="h-0 w-full pb-[52.28%] overflow-hidden">
                        <img
                            class="object-cover w-full"
                            :src="domain + image"
                            :alt="heading"
                            loading="lazy"
                        >
                        <strong
                            v-if="time"
                            class="absolute bottom-0 inline-block px-3 py-1 text-xs font-medium bg-orange"
                        >
                            {{ time }}
                        </strong>
                    </div>
                </div>

                <div class="flex flex-col flex-1">
                    <h5
                        v-if="heading"
                        class="text-lg font-bold sm:text-base"
                    >
                        {{ heading }}
                    </h5>

                    <p
                        v-if="description"
                        class="mt-2 text-sm text-gray-700 mb-4"
                        v-html="description"
                    />

                    <a
                        :href="disable ? null : link"
                        target="_blank"
                        class="mt-auto w-full relative inline-block text-center
                     group focus:outline-none focus:ring cursor-pointer"
                        @click="onOpenSubscribe"
                    >
                        <span
                            class="absolute inset-0 transition-transform
                        translate-x-1.5 translate-y-1.5
                        bg-orange group-hover:translate-y-0 group-hover:translate-x-0"
                            :class="{'bg-disabled' : disable}"
                        />

                        <span
                            class="w-full relative inline-block px-8 py-3
                            text-sm font-bold tracking-widest text-black
                            uppercase border-2 border-current group-active:text-opacity-75
                            flex justify-center gap-2"
                        >
                            <subscribe-is-needed-svg
                                v-if="disable"
                            />
                            Просмотр
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubscribeIsNeededSvg from "@/UI/Icons/SubscribeIsNeededSvg.vue";
import { mapGetters } from "vuex";

export default {
    name: "WebinarCard",
    components: { SubscribeIsNeededSvg, },
    props: {
        image: {
            type: String,
            default: ''
        },
        time: {
            type: String,
            default: ''
        },
        heading: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        link: {
            type: String
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    emits: ["onOpenSubscribe"],
    computed: {
        ...mapGetters({
            isMember: 'isMember'
        }),
        domain() {
            return process.env.VUE_APP_API_HOST;
        }

    },
    methods: {
        onOpenSubscribe() {
            if (this.disable) {
                this.$emit("onOpenSubscribe");
            }
        }
    },
};
</script>

<style scoped>
p {
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
</style>
