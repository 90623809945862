<template>
    <page-loading
        :empty="errors?.empty"
        :message="errors?.empty"
        :status="status"
    >
        <h2
            v-if="data.heading"
            class="text-2xl mb-5"
        >
            {{ data.heading }}
        </h2>
        <div
            v-masonry
            transition-duration="0.3s"
            item-selector=".item"
            class="masonry-container mb-[500px] xl:mb-[290px] lg:mb-[230px] md:mb-0"
        >
            <div
                v-for="link of data.list"
                :key="link.id"
                v-masonry-tile
                class="w-1/5 sm:w-full md:w-1/2 xl:w-1/3 2xl:w-w-1/4 pr-[15px] sm:pr-0 lg:max-w-full pb-[15px] item"
            >
                <div class="pr-2 pb-2">
                    <links-card
                        :links="link.list"
                        :topic="link.heading"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-8">
            <div class="max-w-[600px] flex gap-2">
                <tg-subscribe-vector
                    class="w-[24px]"
                />
                <span v-if="isSubscribe">
                    Если вашего телеграм-канала нет в папке с авторскими телеграм-каналами, вы можете отправить заявку на его добавление
                </span>
                <span v-else>
                    Для компаний, оформивших подписку, доступно добавление телеграм-каналов в папку авторских телеграм-каналов
                </span>
            </div>
            <enter-button class="w-[300px]" @click="handleClick">
                <span class="text-sm">
                    <span v-if="isSubscribe">Добавить телеграм-канал</span>
                    <span v-else>Оформить подписку</span>
                </span>
            </enter-button>
        </div>
    </page-loading>
    <subscribe-modal :popUp="subscribePopUp" :closePopUp="closeSubscribePopUp" />
    <telegram-add-channel :popUp="telegramPopUp" :closePopUp="closeTelegramPopUp" />
</template>

<script>
import LinksCard from "@/components/LinksPage/LinksCard";
import {loadData} from "@/use/load";
import {linksPage} from "@/api";
import {ref, toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import singlePageHandler from "@/handlers/singlePageHandler";
import { overflowShow } from "@/helpers";
import store from "@/store";
import EnterButton from "@/components/Enter/Button";
import TgSubscribeVector from "@/UI/Icons/TgSubscribeVector.vue";
import SubscribeModal from "@/components/Modal/SubscribeModal.vue";
import TelegramAddChannel from "@/components/Modal/TelegramAddChannel.vue";

export default {
    name: "LinksPage",
    components: { LinksCard, PageLoading, EnterButton, TgSubscribeVector, SubscribeModal, TelegramAddChannel },
    setup() {
        const { data: response, status } = loadData({ query: linksPage, handler: singlePageHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        const isSubscribe = store.getters["isSubscribe"];
        const subscribePopUp = ref(false);
        const telegramPopUp = ref(false);

        return {
            data,
            status,
            errors,
            isSubscribe,
            subscribePopUp,
            telegramPopUp,
        };
    },
    methods: {
        handleClick() {
            if (!this.isSubscribe) {
                this.openSubscribePopUp();
            } else {
                this.openTelegramPopUp();
            };
        },
        openSubscribePopUp() {
            this.subscribePopUp = true;
            overflowShow();
        },
        closeSubscribePopUp() {
            this.subscribePopUp = false;
            overflowShow();
        },
        openTelegramPopUp() {
            this.telegramPopUp = true;
            overflowShow();
        },
        closeTelegramPopUp() {
            this.telegramPopUp = false;
            overflowShow();
        }
    }
};
</script>

<style scoped></style>
