export const REQUIRED_FIELD = "Поле обязательно к заполнению";
export const INVALID_EMAIL = "Введите корректный адрес почты";
export const INVALID_DATE = "Введите корректную дату";
export const INVALID_PRICE = "Укажите стоимость в цифах";
export const MEMBER = "Член ARDA";
export const NOT_MEMBER = "Участник сообщества";
export const ADMIN = "Администратор";
export const PREMIUM = "Premium";
export const EMPLOYEE = "Сотрудник";
export const API_HOST = process.env.VUE_APP_API_HOST

export const statusQuery = {
    SUCCESS: "success",
    ERROR: "error",
    PENDING: "pending"
}

export const request = {
    PERSONAL: "personal",
    FAQ: "faq",
    LEADS: "leads",
    LINKS: "links",
    STANDARD: "standard",
    MAIN: "main",
    AUTH: "auth",
    USER: "user",
    USERS: "users",
    CHANGE_PASS: "changePass",
    REG_FORM: "registration",
    JOIN_PERSONAL: "joinPersonal",
    WEBINARS: "webinars",
    CALENDAR: "calendar",
    SUBSCRIBE: "subscribe",
    SUBSCRIBE_ADVANTAGES: "subscribe-advantages",
    SUBSCRIBE_PERIODS: "subscribe-period",
    SUBSCRIBES: 'subscribes',
    ADD_TG_CHANNEL: 'add-tg-channel',
    MAPS: "maps",
    WORKERS: 'workers',
    CITY: 'city',
    EXPERTISE: 'expertise',
    STACK: 'stack',
    FORMAT: 'format',
    MARKETS: 'markets',
    SERVICES: 'services',
    EVENT_TYPE: 'directory-types',
    NEW_EVENT: 'newEvent',
    CARDS: 'cards',
    REPORTS: 'reports',
    SCRIPTS: 'script',
    PR_SUPPORT: "prSupport",
    VC_BLOG_ANALYTICS: "vcBlogAnalytics",
    VC_BLOGS: "vcBlogs",
    USER_VC_BLOG_STATS: "userVcBlogStats",
    FORGOT_PASS: 'forgotPass',
    RESET_PASS: 'resetPass',
    WHATS_NEW: 'messages',
    USER_COMPANY: "company-users",
    ACTIVATE_ACCOUNT_EMAIL: "activate-account",
    ACTIVATE_ACCOUNT_FOR_ADMIN_EMAIL: "activate-account-for-admin",
    SEND_EMPLOYEE_EMAIL_ACTIVATE: "send-email-to-confirm-employee",
    EVENTS: "collection-events",
    EVENTS_ALL: "get-all-cards-events",
    EVENT_CARD: "get-card-event"
}

export const API_REQUEST = {
    [request.PERSONAL]: "page-personal",
    [request.LEADS]: "page-lead",
    [request.LINKS]: "page-link",
    [request.FAQ]: "page-question",
    [request.MAIN]: "page-main",
    [request.AUTH]: "users/verify",
    [request.STANDARD]: "page-standard",
    [request.USER]: "/users/me",
    [request.CHANGE_PASS]: "/auth/change-password",
    [request.WEBINARS]: "/page-webinar",
    [request.CALENDAR]: "/page-event",
    [request.SUBSCRIBE]: "/page-subscribe",
    [request.MAPS]: "/page-map",
    [request.WORKERS]: "/directory-workers",
    [request.CITY]: "/directory-cities",
    [request.EXPERTISE]: "/directory-expertises",
    [request.SUBSCRIBE_ADVANTAGES]: "/subscribe-advantages",
    [request.SUBSCRIBE_PERIODS]: "/subscribe-periods",
    [request.SUBSCRIBES]: "/subscribes",
    [request.ADD_TG_CHANNEL]: "/adding-tg-channels",
    [request.STACK]: "/directory-stacks",
    [request.FORMAT]: "/directory-formats",
    [request.MARKETS]: "/directory-markets",
    [request.CARDS ]: "/collection-maps",
    [request.SERVICES ]: "/directory-services",
    [request.EVENT_TYPE ]: "/directory-types",
    [request.JOIN_PERSONAL]: "/form-personals",
    [request.NEW_EVENT ]: "/form-webinars",
    [request.REG_FORM]: "/form-mains",
    [request.REPORTS]: "/page-report",
    [request.SCRIPTS]: "/script",
    [request.PR_SUPPORT]: "/page-pr-support",
    [request.VC_BLOG_ANALYTICS]: "/page-vc-blog-analytics",
    [request.VC_BLOGS]: "/vc-blogs",
    [request.USER_VC_BLOG_STATS]: "/users/me/vc-blog-stats",
    [request.FORGOT_PASS]: "/auth/forgot-password",
    [request.RESET_PASS]: "/users/resetPassword",
    [request.WHATS_NEW]: "/page-whats-new",
    [request.USERS]: "/users",
    [request.USER_COMPANY]: "/company-users",
    [request.ACTIVATE_ACCOUNT_EMAIL]: "/activate-account",
    [request.ACTIVATE_ACCOUNT_FOR_ADMIN_EMAIL]: "/activate-account-for-admin",
    [request.SEND_EMPLOYEE_EMAIL_ACTIVATE]: "/send-email-to-confirm-employee",
    [request.EVENTS]: "/collection-events",
    [request.EVENTS_ALL]: "/get-all-cards-events",
    [request.EVENT_CARD]: "/get-card-event"
}

export const fields = {
    name: 'name',
    email: 'email',
    phone: 'phone',
    site: 'site',
    lastName: 'lastName',
    company: 'company',
    role: 'role',
    userPermissions: 'userPermissions',
    userRole: 'userRole',
    post: 'post',
    resourceMap: 'resourceMap',
    programmers: 'mostProgrammers',
    stability: 'stability',
    outstaffing: 'experience',
    whiteLabelOutstaffing: 'whiteLabel',
    agreement: 'privacyPolicy',
    username: 'username',
    position: 'post',
    oldPassword: 'currentPassword',
    newPassword: 'password',
    repeatPassword: 'passwordConfirmation',
    login: 'identifier',
    password: 'password',
    type: 'format',
    start: 'dateStart',
    end: 'dateEnd',
    city: 'city',
    link: 'link'
}

export const cards = {
    company: 'company',
    cities: 'cities',
    newCity: 'newCity',
    site: 'site',
    description: 'description',
    contacts: 'contacts',
    rusProfile: 'rusProfile',

    secondMemberFullName: 'secondMemberFullName',
    secondMemberPost: 'secondMemberPost',
    secondMemberMail: 'secondMemberMail',
    secondMemberTelegram: 'secondMemberTelegram',

    numberOfStaff: 'numberOfStaff',
    isAccredited: 'isAccredited',
    isBroker: 'isBroker',
    formats: 'formats',
    services: 'services',
    newServices: 'newServices',
    commentsToServeices: 'commentsToServeices',
    expertise: 'expertise',
    newExpertise: 'newExpertise',
    commentToDomainExpertise: 'commentToDomainExpertise',
    stacks: 'stacks',
    newStacks: 'newStacks',
    markets: 'markets',
    newMarkets: 'newMarkets',
    commentToMarkets: 'commentToMarkets',
    content: 'content',
    industrialDevelopments: 'industrialDevelopments',
    benchMap: 'benchMap',
    commentToBenchMap: 'commentToBenchMap',
    requestShowcase: 'requestShowcase',
    commentToRequestShowcase: 'commentToRequestShowcase'
}

export const filterList = {
    CITY: "cities",
    WORKERS: "workers",
    EXPERTISE: "expertise",
    STACKS: "stacks",
    SERVICES: "services",
    FORMAT: "format",
    MARKETS: "markets",
    BENCHMAP: "benchMap",
    REQUESTSHOWCASE: "requestShowcase"
}

export const filterServer = {
    [filterList.CITY]: "cities",
    [filterList.FORMAT]: "formats",
    [filterList.WORKERS]: "workers",
    [filterList.EXPERTISE]: "expertise",
    [filterList.STACKS]: "stacks",
    [filterList.SERVICES]: "services",
    [filterList.MARKETS]: "markets"
};

export const filterNotNull = {
    [filterList.BENCHMAP]: "benchMap",
    [filterList.REQUESTSHOWCASE]: "requestShowcase"
}

export const fieldsReverse = Object.keys(fields).reduce((acc, i) => {
    acc[fields[i]] = i;
    return acc
}, {})

export const KEYS = {
    USER: 'user',
    TOKEN: 'token'
}

export const STATUS = {
    ERROR: 'error',
    SUCCESS: 'success'
}

export const publicSections = [
    'page-main',
    'users/verify'
]

export const vcBlogAnalyticsConstants = {
    INVALID_BLOG_URL_MESSAGE: `Ссылка должна начинаться с "https://vc.ru/"`,
    SERVER_ERROR_MESSAGE: "Ошибка сервера. Попробуйте позже",
    URL_ALREADY_EXISTS_MESSAGE: "Этот блог уже привязан к другой компании",
    CUMULATIVE_VC_BLOG_STATS_KEY: "total",
    CUMULATIVE_VC_BLOG_STATS_LABEL: "за всё время"
};

export const subscribeProviderKey = Symbol("subscribeProviderKey");

export const subscribeModalActions = {
    OPEN_SUBSCRIBE_MODAL: "openSubscribeModal",
    CLOSE_SUBSCRIBE_MODAL: "closeSubscribeModal"
}
