<template>
    <ul
        v-if="periodIds"
        class="flex flex-wrap gap-5"
    >
        <li
            v-for="periodId of periodIds"
            :key="periodId"
        >
            <period-selection-button
                :id="periodId"
                :label="generatePeriodButtonLabel(periodId)"
                :is-selected="selectedPeriodId === periodId"
                :disabled="isDisabled(periodId)"
                @select="handleSelect"
                @on-open-subscribe="openSubscribePopUp"
            />
        </li>
    </ul>
    <subscribe-is-needed 
        :popUp="subscribePopUp"
        :closePopUp="closeSubscribePopUp"
    />
</template>

<script>
import SubscribeIsNeeded from "@/components/Subscribe/SubscribeIsNeeded.vue";
import PeriodSelectionButton from "@/components/VcBlogAnalytics/components/PeriodSelectionButton";
import { vcBlogAnalyticsConstants } from "@/constants";
import { overflowShow } from "@/helpers";
import { ref } from "vue";

const { CUMULATIVE_VC_BLOG_STATS_KEY, CUMULATIVE_VC_BLOG_STATS_LABEL } = vcBlogAnalyticsConstants;
  
export default {
    name: "PeriodSelectionPanel",
    components: { PeriodSelectionButton, SubscribeIsNeeded },
    props: {
        periodIds: {
            type: Array,
            required: true
        },

        initialSelectedPeriodId: {
            type: String,
            required: true
        },

        isSubscribe: {
            type: Boolean,
            default: false,
        }
    },

    emits: [ "period-change" ],

    setup() {
        const subscribePopUp = ref(false);

        return {
            subscribePopUp,
        }
    },

    data() {
        return {
            selectedPeriodId: this.initialSelectedPeriodId
        }
    },

    methods: {
        handleSelect(periodId) {
            this.selectedPeriodId = periodId;
            this.$emit("period-change", periodId);
        },

        generatePeriodButtonLabel(periodKey) {
            if (periodKey === CUMULATIVE_VC_BLOG_STATS_KEY) {
                return CUMULATIVE_VC_BLOG_STATS_LABEL;
            }

            return `${periodKey} год`;
        },

        isDisabled(periodId) {
            return !this.isSubscribe && periodId !== CUMULATIVE_VC_BLOG_STATS_KEY
        },

        openSubscribePopUp() {
            this.subscribePopUp = true;
            overflowShow();
        },
        closeSubscribePopUp() {
            this.subscribePopUp = false;
            overflowShow();
        }
    },
}
</script>

<style scoped>

</style>
