<template>
    <a
        target="_blank"
        v-bind="{ download: !disabled, href: !disabled ? url : null }"
        class="flex gap-2 items-center cursor-pointer item border-black border-2 px-4"
        :class="{ 'bg-disabled': disabled }"
        @click="handleClick"
    >   
        <subscribe-is-needed-svg v-if="disabled"/>
        <span>
            Скачать отчет
        </span>
        <img
            width="20"
            height="20"
            src="@/assets/html.svg"
            alt=""
        >
    </a>
</template>

<script>
import SubscribeIsNeededSvg from '@/UI/Icons/SubscribeIsNeededSvg.vue';

export default {
    name: "ReportsDownload",
    components: { SubscribeIsNeededSvg },
    props: {
        url: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: true,
        }
    },
    emits: [ 'onOpenSubscribe' ],
    methods: {
        handleClick(event) {
            if (this.disabled) {
                event.preventDefault();
                this.$emit('onOpenSubscribe');
            }
        }
    }
}
</script>
