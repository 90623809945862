<template>
    <div class="flex flex-wrap items-center justify-between max-w-full mb-10">
        <h2
            v-if="heading"
            class="text-2xl"
        >
            {{ heading }}
        </h2>
        <div class="flex flex-wrap items-center justify-end gap-8">
			<enter-button @click="openPopUp">
			  Измените нашу карточку
			</enter-button>
        </div>
    </div>

    <resource-filter
        @on-change="apply"
        @on-open="openSubscribePopUp"
        :isSubscribe="isSubscribe"
    />

    <resource-board
        :status="status"
        :list="list"
        :message="message"
        :error="isError"
        @on-click="clickCard"
    />

    <modal-template
        v-if="openModal"
        @on-close="closeModal"
    >
        <resource-card-full
            :company="selected.company"
            :description="selected.description"
            :site="selected.site"
            :city="selected.city"
            :expertises="selected.expertises"
            :comment-to-domain-expertise="selected.commentToDomainExpertise"
            :format="selected.format"
            :is-member="selected.isMember"
            :is-subscribe="selected.isSubscribe"
            :markets="selected.markets"
            :comment-to-markets="selected.commentToMarkets"
            :services="selected.services"
            :comments-to-serveices="selected.commentsToServeices"
            :workers="selected.workers"
            :content="selected.content"
            :bench-map="selected.benchMap"
            :comment-to-bench-map="selected.commentToBenchMap"
            :contacts="selected.contacts"
            :is-broker="selected.isBroker"
            :is-accredited="selected.isAccredited"
            :request-showcase="selected.requestShowcase"
            :comment-to-request-showcase="selected.commentToRequestShowcase"
            :industrial-developments="selected.industrialDevelopments"
            :rus-profile="selected.rusProfile"
            :stack="selected.stacks"
            @close-modal="closeModal"
        />
    </modal-template>
    <modal-template
        v-if="popUp"
        @on-close="closePopUp"
    >
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg font-medium">
                Как внести изменения в карточку?
            </h2>
        </div>
        <p>
          Отправьте письмо с необходимыми изменениями на
          <a
                class="underline hover:no-underline"
                href="mailto:it@arda.digital"
            >
              it@arda.digital
            </a>
        </p>
    </modal-template>
    <subscribe-is-needed
        :popUp="subscribePopUp"
        :closePopUp="closeSubscribePopUp"
    />
</template>

<script>
import ResourceFilter from "@/components/ResourcePage/ResourceFilter";
import ModalTemplate from "@/components/Modal/ModalTemplate";
import EnterButton from "@/components/Enter/Button";
import { overflowHidden, overflowShow } from "@/helpers";
import ResourceCardFull from "@/components/ResourcePage/ResourceCardFull";
import { filter } from "@/use/filter";
import { filters } from "@/api/formattedResponse";
import ResourceBoard from "@/components/ResourcePage/ResourceBoard";
import router from "@/router";
import store from "@/store";
import { ref } from "vue";
import SubscribeIsNeeded from "../Subscribe/SubscribeIsNeeded.vue";

export default {
    name: "ResourceContent",

    components: {
        ResourceBoard,
        ModalTemplate,
        ResourceCardFull,
        ResourceFilter,
        EnterButton,
        SubscribeIsNeeded,
    },
    props: {
        heading: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        isError: {
            type: Boolean,
            default: false
        },
        cards: {
            type: Array,
            default: () => []
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    setup() {
        const { onSubmit, status, error } = filter(filters);
        const isSubscribe = store.getters["isSubscribe"];
        const isMember = store.getters["isMember"];

        const subscribePopUp = ref(false);

        return { 
            onSubmit, 
            status, 
            error, 
            isSubscribe, 
            isMember, 
            subscribePopUp,
        };
    },
    data() {
        return {
            list: this.cards,
            popUp: false,
            index: null,
          	cardEdit: this.$config.routes.account.cardEdit.name
        };
    },
    computed: {
        openModal() {
            return !!this.list.find( card => card.company === this.$route.params.company)
        },
        selected() {
            return this.list.find( card => card.company === this.$route.params.company)
        }
    },
    methods: {
        apply(filters) {
            this.onSubmit(filters).then(response => {
                this.list = response
            })
        },
        clickCard(companyName) {
            router.push({ path: `/account/resource/${encodeURIComponent(companyName)}` })
            overflowHidden();
        },
        openPopUp() {
            this.popUp = true;
            overflowHidden();
        },
        closePopUp() {
            this.popUp = false;
            overflowShow();
        },
        closeModal() {
            router.push({ path: `/account/resource` })
            overflowShow();
        },
        openSubscribePopUp() {
            this.subscribePopUp = true;
            overflowShow();
        },
        closeSubscribePopUp() {
            this.subscribePopUp = false;
            overflowShow();
        }
    }
};
</script>

<style scoped></style>
