<template>
    <nav
        class="flex justify-between items-center fixed left-0 top-0 z-40 w-screen max-h-[50px] h-full bg-orange w-full p-3"
        @click.self="closeMenu"
    >
        <button
            v-if="showMenu"
            class="w-auto flex justify-center align-middle"
            @click="closeMenu"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                class="w-8 h-8"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>
        </button>

        <button
            v-if="!showMenu"
            type="button"
            class="w-auto flex justify-center align-middle"
            @click="openMenu"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                class="w-8 h-8"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
            </svg>
        </button>

        <div>
           <b>
                <span v-if="isSubscribe" class="flex gap-2">
                    <subscribe-star-svg />
                    <span>Premium</span>
                </span>
                <span v-else>
                    Free
                </span>
            </b>
        </div>

        <div class="flex justify-between items-center gap-4">
            <router-link
                class="hover:bg-white p-1 rounded-full border-2 border-transparent hover:border-black"
                :to="{ name: mainLink }"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="w-6 h-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                </svg>
            </router-link>

            <router-link
                class="hover:bg-white p-1 rounded-full border-2 border-transparent hover:border-black"
                :to="{ name: profileLink }"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="w-6 h-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                </svg>
            </router-link>

            <div
                class="hover:bg-white p-1 rounded-full border-2 border-transparent hover:border-black cursor-pointer"
                @click="onClick"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="w-6 h-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                    />
                </svg>
            </div>
        </div>

        <ul
            v-if="showMenu"
            class="border-2 border-black flex flex-col absolute bg-orange pt-7 z-40 left-0 top-[50px] max-w-2xl h-full min-h-[calc(100vh-50px)] overflow-y-auto px-5 pb-14 md:max-w-none md:min-w-screen md:w-full md:min-h-[calc(100vh-50px)]"
        >
            <nav-item
                v-for="(nav, index) of Navs"
                :key="index + nav"
                :nav="nav"
            />
        </ul>
    </nav>
</template>

<script>
import NavItem from "@/components/Navigation/NavItem";
import store from "@/store";
import { mapMutations } from "vuex";
import SubscribeStarSvg from '@/UI/Icons/SubscribeStarSvg.vue';

export default {
    name: "NavBarHorizontal",
    components: { NavItem, SubscribeStarSvg },
    props: {
        showMenu: {
            type: Boolean
        }
    },
    emits: [ 'closeMenu', 'openMenu' ],
    data() {
        return {
            isSubscribe: store.getters['isSubscribe'],
            profileLink: this.$config.routes.account.profile.name,
            mainLink: this.$config.routes.account.main.name,
            Navs: [
                {
                    title: "Стандарты",
                    page: this.$config.routes.account.standards.name
                },
                {
                    title: "Ресурсная карта",
                    page: this.$config.routes.account.resource.name
                },
                {
                    title: "Аналитика",
                    page: this.$config.routes.account.reports.name
                },
                {
                    title: "Аналитика VC-блога",
                    page: this.$config.routes.account.vcBlogAnalytics.name
                },
                {
                    title: "Обмен лидами",
                    page: this.$config.routes.account.exchange.name
                },
                {
                    title: "PR-поддержка",
                    page: this.$config.routes.account.prSupport.name
                },
                {
                    title: "Вебинары",
                    page: this.$config.routes.account.webinars.name
                },
                {
                    title: "Календарь",
                    page: this.$config.routes.account.calendar.name
                },
                {
                    title: "FAQ",
                    page: this.$config.routes.account.faq.name
                },
                {
                    title: "Ссылки",
                    page: this.$config.routes.account.links.name
                },
                {
                    title: "Подписка",
                    page: this.$config.routes.account.subscribe.name
                }
            ]
        };
    },
    methods: {
        ...mapMutations([ 'logout' ]),
        onClick() {
            this.$router.push('/')
            this.logout();
        },

        closeMenu() {
            return this.$emit("closeMenu");
        },

        openMenu() {
            return this.$emit("openMenu");
        }
    }
};
</script>

<style scoped>
ul {
  scrollbar-width: thin;
  scrollbar-color: #FBB724 transparent;
}

ul::-webkit-scrollbar {
  width: 2px;
}

ul::-webkit-scrollbar-track {
  background: transparent;
}

ul::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 2px solid #FBB724;
}

</style>
