<template>
    <table>
        <thead>
            <tr class="border-b border-gray-300 bg-back-gray">
                <th class="py-[10px] text-sm text-center w-[400px]">Опции личного кабинета</th>
                <th class="py-[10px] text-sm text-center w-[250px]">Без подписки (free account)</th>
                <th class="py-[10px] text-sm text-center w-[250px]">С подпиской (premium account)</th>
                <hr class="w-full"/>
            </tr>
        </thead>
        <tbody>
            <tr 
                class="text-sm border-b border-gray-300 "
                v-for="item in data" 
                :key="item.heading"
            >
                <td class="p-[10px] w-[400px]">{{ item.heading }}</td>
                <td class="p-[10px] w-[250px] text-center">{{ item.valueFree }}</td>
                <td class="p-[10px] w-[250px] text-center">{{ item.valuePremium }}</td>
                
            </tr>
        </tbody>
    </table>
</template>

<script>
import { getSubscribeAdvantages } from "@/api";
import { mainHandler } from "@/handlers";
import { ref, onMounted } from "vue";

export default {
    name: "SubscribeBenefitsTable",
    setup() {
        const data = ref([]);
        const errors = ref(null);
        const status = ref("pending");

        const fetchBenefitsData = async () => {
            try {
                const response = await getSubscribeAdvantages();
                const result = mainHandler(response.data);

                if (result.error) {
                    throw result.errors;
                }

                data.value = result.data;
                status.value = "success";
            } catch (error) {
                errors.value = error;
                status.value = "error";
            }
        };

        onMounted(fetchBenefitsData);

        return {
            data,
            errors,
            status,
        };
    },
};
</script>

<style scoped></style>
