import LandingPage from "@/pages/LandingPage";
import MainPage from "@/pages/MainPage";
import ProfilePage from "@/pages/ProfilePage";
import StandardsPage from "@/pages/StandardsPage";
import WebinarsPage from "@/pages/WebinarsPage";
import ResourcePage from "@/pages/ResourcePage";
import CalendarPage from "@/pages/CalendarPage";
import FAQPage from "@/pages/FAQPage";
import ExchangePage from "@/pages/ExchangePage";
import LinksPage from "@/pages/LinksPage";
import config from "@/config";
import ProfileLayout from "@/layouts/ProfileLayout";
import ErrorPage from "@/pages/ErrorPage";
import AuthPage from "@/pages/AuthPage";
import ReportsPage from "@/pages/ReportsPage";
import PrSupportPage from "@/pages/PrSupportPage";
import FormResourceMap from "@/pages/FormResourceMap";
import VcBlogAnalyticsPage from "@/pages/VcBlogAnalyticsPage";
import WhatsNewPage from "@/pages/WhatsNewPage.vue";
import SubscribePage from "@/pages/SubscribePage.vue";

export default [
    {
        path: "/:options?/:company?",
        name: config.routes.landing.name,
        component: LandingPage,
        meta: {
            title: "IT-кластер ассоциации развития digital-агентств ARDA"
        }
    },
    { path: '/:pathMatch(.*)', component: ErrorPage },
    {
        path: "/account",
        redirect: () => "account/main/",
        name: config.routes.account.name,
        component: ProfileLayout,
        children: [
            {
                path: "main/",
                name: config.routes.account.main.name,
                component: MainPage,
                pathToRegexpOptions: { strict: true },
                meta: {
                    title: "Личный кабинет"
                }
            },
            {
                path: "auth/",
                name: config.routes.account.auth.name,
                component: AuthPage,
                pathToRegexpOptions: { strict: true },
                meta: {
                    title: "Авторизация"
                }
            },
            {
                path: "profile/",
                name: config.routes.account.profile.name,
                component: ProfilePage,
                pathToRegexpOptions: { strict: true },
                meta: {
                    title: "Профиль"
                }
            },
            {
                path: "standards/",
                name: config.routes.account.standards.name,
                pathToRegexpOptions: { strict: true },
                component: StandardsPage,
                meta: {
                    title: "Стандарты"
                }
            },
            {
                path: "webinars/",
                name: config.routes.account.webinars.name,
                pathToRegexpOptions: { strict: true },
                component: WebinarsPage,
                meta: {
                    title: "Вебинары"
                }
            },
            {
                path: "resource/:company?",
                name: config.routes.account.resource.name,
                pathToRegexpOptions: { strict: true },
                component: ResourcePage,
                meta: {
                    title: "Ресурсная карта"
                }
            },
            {
                path: "calendar/",
                name: config.routes.account.calendar.name,
                pathToRegexpOptions: { strict: true },
                component: CalendarPage,
                meta: {
                    title: "Календарь мероприятий"
                }
            },
            {
                path: "FAQ/",
                name: config.routes.account.faq.name,
                pathToRegexpOptions: { strict: true },
                component: FAQPage,
                meta: {
                    title: "FAQ"
                }
            },
            {
                path: "exchange/",
                name: config.routes.account.exchange.name,
                pathToRegexpOptions: { strict: true },
                component: ExchangePage,
                meta: {
                    title: "Правила обмена лидами/клиентами"
                }
            },
            {
                path: "links/",
                name: config.routes.account.links.name,
                pathToRegexpOptions: { strict: true },
                component: LinksPage,
                meta: {
                    title: "Полезные ссылки"
                }
            },
            {
                path: "reports/",
                name: config.routes.account.reports.name,
                pathToRegexpOptions: { strict: true },
                component: ReportsPage,
                meta: {
                    title: "Аналитика"
                }
            },
            {
                path: "vc-blog-analytics",
                name: `${config.routes.account.vcBlogAnalytics.name}`,
                pathToRegexpOptions: { strict: true },
                component: VcBlogAnalyticsPage,
                meta: {
                    title: "Аналитика VC-блога"
                }
            },
            {
                path: "pr-support/",
                name: config.routes.account.prSupport.name,
                pathToRegexpOptions: { strict: true },
                component: PrSupportPage,
                meta: {
                    title: "PR-поддержка"
                }
            },
            {
                path: "form-map/",
                name: config.routes.account.formMap.name,
                pathToRegexpOptions: { strict: true },
                component: FormResourceMap,
                meta: {
                    title: "Форма"
                }
            },
            {
                path: "whats-new/",
                name: config.routes.account.whatsNew.name,
                pathToRegexpOptions: { strict: true },
                component: WhatsNewPage,
                meta: {
                    title: "Что у нас происходит?"
                }
            },
            {
                path: "subscribe/",
                name: config.routes.account.subscribe.name,
                pathToRegexpOptions: { strict: true },
                component: SubscribePage,
                meta: {
                    title: "Подписка"
                }
            }
        ]
    }
];
